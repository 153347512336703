import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { FIREBASE, getCurrentRegion } from '../config';

const app = initializeApp(FIREBASE);
if (process.env.NODE_ENV === 'development') {
  //firebase.firestore().useEmulator("localhost", 8080);
  //firebase.functions().useEmulator("localhost", 5001);
}

export const db = getFirestore(app);
export const auth = getAuth(app);
export const fbFunctions = getFunctions(app, getCurrentRegion());

export default app;
