import { httpsCallable } from '@firebase/functions';
import {
  DocumentData,
  collection,
  deleteField,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { clearUndefined } from '../utils/helpers/firebaseSanitize.helpers';
import { OrganisationUsers } from '../utils/types/organisation';
import { User } from '../utils/types/user';
import { db, fbFunctions } from './firebase';

export interface UsersListItem {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  company: string;
  jobTitle?: string;
  expiry?: Date;
  type: string;
  archived?: boolean;
  permission?: string;
  organization_id?: number;
  organization_role?: string;
  access_level?: string;
  license?: string;
  disabled?: boolean;
}

const transformUser = (doc: DocumentData) => {
  const data = doc.data();

  return {
    id: doc.id,
    firstName: data.firstName,
    activityFeedLastSeen: data.activityFeedLastSeen,
    email: data.email,
    lastName: data.lastName,
    company: data.company,
    officeAddress: data.officeAddress,
    jobTitle: data.jobTitle,
    role: data.role,
    regions: data.regions?.join(', '),
    clientTypes: data.clientTypes?.join(', '),
    assetClasses: data.assetClasses?.join(', '),
    strategies: data.strategies
      ?.map(
        (strategy: any) =>
          `${strategy.assetClass};${strategy.fundUniverse};${strategy.sector}`
      )
      .join(', '),
    expiry: data.expiry ? new Date(data.expiry.seconds * 1000) : undefined,
    archived: !data.archived ? false : true,
    permission: data.permission,
    phone:
      data.phone?.phoneNumber &&
      `${data.phone.dialCode}${data.phone.phoneNumber}`,
    mobileNumber:
      data.mobileNumber?.phoneNumber &&
      `${data.mobileNumber.dialCode}${data.mobileNumber.phoneNumber}`,
    type: 'user',
    organization_id: data.organization_id,
    organization_role: data.organization_role,
  };
};

export const getAllFirebase = async (
  limitNum?: number
): Promise<Array<User>> => {
  let usersQuery = query(collection(db, 'users'), orderBy('firstName'));

  if (limit) {
    usersQuery = query(usersQuery, limit(limitNum || 500));
  }

  try {
    const usersSnapshot = await getDocs(usersQuery);
    return usersSnapshot.docs.map((doc) => transformUser(doc));
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

export const getUserDetailsFirebase = async (
  id: string
): Promise<User | undefined> => {
  const usersCollection = collection(db, 'users');
  const userQuery = query(usersCollection, where('id', '==', id));

  try {
    const userSnapshot = await getDocs(userQuery);

    if (!userSnapshot.empty) {
      const userDocument = userSnapshot.docs[0];
      const userData = userDocument.data();

      return {
        id: userDocument.id,
        firstName: userData?.firstName,
        lastName: userData?.lastName,
        email: userData?.email,
        officeAddress: userData?.officeAddress,
        company: userData?.company,
        jobTitle: userData?.jobTitle,
        role: userData?.role,
        regions: userData?.regions?.join(', '),
        clientTypes: userData?.clientTypes?.join(', '),
        assetClasses: userData?.assetClasses?.join(', '),
        strategies: userData?.strategies
          ?.map(
            (strategy: any) =>
              `${strategy.assetClass};${strategy.fundUniverse};${strategy.sector}`
          )
          .join(', '),
        expiry: userData.expiry
          ? new Date(userData.expiry.seconds * 1000)
          : undefined,
        archived: userData?.archived,
        permission: userData?.permission,
        type: 'user',
        avatar: userData?.avatar,
        organization_id: userData?.organization_id,
        organization_role: userData?.organization_role,
      };
    }
  } catch (error) {
    console.error('Error fetching user details:', error);
    throw error;
  }

  return undefined;
};

export const getUserDetailsFirebaseByEmail = async (
  email: string
): Promise<User | undefined> => {
  const usersCollection = collection(db, 'users');
  const userQuery = query(usersCollection, where('email', '==', email));

  try {
    const userSnapshot = await getDocs(userQuery);

    if (!userSnapshot.empty) {
      const userDocument = userSnapshot.docs[0];
      const userData = userDocument.data();

      return {
        id: userDocument.id,
        firstName: userData?.firstName,
        lastName: userData?.lastName,
        email: userData?.email,
        officeAddress: userData?.officeAddress,
        company: userData?.company,
        jobTitle: userData?.jobTitle,
        role: userData?.role,
        regions: userData?.regions?.join(', '),
        clientTypes: userData?.clientTypes?.join(', '),
        assetClasses: userData?.assetClasses?.join(', '),
        strategies: userData?.strategies
          ?.map(
            (strategy: any) =>
              `${strategy.assetClass};${strategy.fundUniverse};${strategy.sector}`
          )
          .join(', '),
        expiry: userData.expiry
          ? new Date(userData.expiry.seconds * 1000)
          : undefined,
        archived: userData?.archived,
        permission: userData?.permission,
        type: 'user',
        avatar: userData?.avatar,
        organization_id: userData?.organization_id,
        organization_role: userData?.organization_role,
      };
    }
  } catch (error) {
    console.error('Error fetching user details:', error);
    throw error;
  }

  return undefined;
};

export const updateUserFirebase = async (user: User): Promise<void> => {
  const userRef = doc(db, 'users', user.id);

  try {
    await updateDoc(userRef, clearUndefined(user));
  } catch (error) {
    console.error('Error updating user:', error);
    throw error;
  }
};

export const resetUserDeviceIdFirebase = async (
  userId: string
): Promise<void> => {
  const userRef = doc(db, 'users', userId);

  try {
    await updateDoc(userRef, {
      userDeviceId: deleteField(),
    });
  } catch (error) {
    console.error('Error resetting user device ID:', error);
    throw error;
  }
};

export const archiveUserFirebase = async (
  id: string,
  archived: boolean
): Promise<any> => {
  const manageUserPermissions = httpsCallable(
    fbFunctions,
    'manageUserPermissions'
  );

  console.log('archiving user:', id, archived);

  try {
    const result = await manageUserPermissions({ id, archived });
    console.log(result);
    return result.data;
  } catch (error) {
    console.error('Error archiving user:', error);
    throw error;
  }
};

export const changeRoleFirebase = async (
  id: string,
  role: string
): Promise<any> => {
  try {
    const manageUserPermissions = httpsCallable(
      fbFunctions,
      'manageUserPermissions'
    );
    const result = await manageUserPermissions({ id, role });

    return result.data;
  } catch (error) {
    console.error('Error changing user role:', error);
    throw error;
  }
};

export const getActivityFeedFirebase = async (): Promise<any> => {
  try {
    const getActivityFeed = httpsCallable(fbFunctions, 'getActivityFeed');

    const result = await getActivityFeed({
      limit: 100,
      date: Date.now(),
    });

    return result.data;
  } catch (error) {
    console.error('Error fetching activity feed:', error);
    throw error;
  }
};

export const getRealAppUsers = async () => {
  const users = await getAllFirebase();
  /* const excludedEmail = [
    'gmail.com',
    'fundpath.co.uk',
    'athlonsofia.com',
    'weareathlon.com',
    'stonefieldgrp.com',
    'icloud.com',
  ];
  const realUsers = users.filter((user: User) => {
    for (let i = 0; i < excludedEmail.length; i++) {
      if (user.email.indexOf(excludedEmail[i]) > -1) {
        return false;
      }
    }

    return true;
  }); */

  //currently returning all users, revert to real users if needed!
  return users;
};

export const triggerResetPasswordEmail = async (
  email: string
): Promise<any> => {
  const sendResetPasswordLink = httpsCallable(
    fbFunctions,
    'sendResetPasswordEmail'
  );

  try {
    const result = await sendResetPasswordLink({
      email,
    });
    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const triggerSendEmail = async (
  senderName: string,
  recipientEmail: string,
  subject: string,
  templateId: string,
  dynamicTemplateData: Array<Object>
) => {
  const sendEmail = httpsCallable(fbFunctions, 'sendEmail');

  try {
    await sendEmail({
      sender_email: 'noreply@fundpath.net',
      sender_name: senderName,
      recipient_email: recipientEmail,
      subject: subject,
      template_id: templateId,
      template_variables: dynamicTemplateData,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getTemplate = (templateName: string, firstName: string) => {
  switch (templateName) {
    case 'go':
      return {
        id: 'z86org85r0kgew13',
        subject: `Welcome to Fundpath Go, ${firstName}`,
      };
    case 'pro':
      return {
        id: '351ndgw1o0dlzqx8',
        subject: `Welcome to Fundpath Pro, ${firstName}`,
      };
    case 'all':
      return {
        id: '3yxj6lj158xldo2r',
        subject: `Welcome to Fundpath Go + Pro, ${firstName}`,
      };
    default:
      return {
        id: '',
        subject: ``,
      };
  }
};

export const fetchOrganisationUsers = async (
  organizationId: number
): Promise<OrganisationUsers> => {
  const organisationUsers = httpsCallable(
    fbFunctions,
    'getUsersByOrganizationId'
  );
  try {
    const result = await organisationUsers({
      organization_id: organizationId,
    });
    return result.data as OrganisationUsers;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
