import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  MenuItem,
  Select,
  SvgIcon,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { DatePicker } from '@material-ui/pickers';
import React, { useCallback, useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { ReactComponent as Cancel } from '../../assets/icons/cancel.svg';
import { ReactComponent as SaveChanges } from '../../assets/icons/save-changes.svg';
import { InvitesStore } from '../../providers/invites';
import { ORGANISATIONS_VIEW_ROUTE } from '../../routes/Types';
import { LAYOUT } from '../../utils/constants/layout.constants';
import {
  USER_LICENSE_OPTIONS,
  UserLicense,
} from '../../utils/constants/userLicenses.constats';
import {
  ORGANISATION_ROLE_OPTIONS,
  UserOrganisationRole,
} from '../../utils/types/organisation';
import StyledInput from '../common/StyledInput';
import Title from '../common/Title';
import TopPannel from '../common/TopPannel';
import WrappedDialog from '../common/WrappedDialog';

interface UsersAddContentProps {}

export interface UsersFormFields {
  firstName: string;
  lastName: string;
  email: string;
  company: string;
  officeAddress: string;
  organization_role?: UserOrganisationRole;
  expiry?: Date;
  license: UserLicense;
}

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: 64,
    background: theme.palette.background.paper,
    [theme.breakpoints.up('md')]: {
      marginTop: LAYOUT.TopPanelHeight + LAYOUT.TopPanelBottomMargin,
      width: `calc(100% - ${LAYOUT.DrawerWidth}px)`,
      marginLeft: LAYOUT.DrawerWidth,
    },
  },
  title: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(5),
  },
  icon: {
    paddingRight: theme.spacing(2),
  },
  button: {
    height: 40,
    borderRadius: 10,
    justifyContent: 'flex-start',
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
  input: {
    'label + &': {
      marginTop: 0,
      color: theme.palette.text.secondary,
    },
  },
  textField: {
    width: '100%',
  },
  errorMessage: {
    height: theme.spacing(6),
  },
  select: {
    paddingLeft: 0,
  },
}));

const UsersAddContent: React.FC<UsersAddContentProps> = (): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const [updateStarted, setUpdateStarted] = useState(false);

  const matchesMediaQuery = useMediaQuery(theme.breakpoints.up('md'), {
    noSsr: true,
  });

  const history = useHistory();
  const { orgId } = useParams<{ orgId: string }>();

  const handleRedirectLinkClick = () => {
    history.push(`${ORGANISATIONS_VIEW_ROUTE.replace(':orgId', orgId)}`);
  };

  const {
    state: { updatingInv, errorInv },
    addUserInvite,
  } = useContext(InvitesStore);

  const [roleOptions, setRoleOptions] = useState(ORGANISATION_ROLE_OPTIONS);
  const [isRoleDisabled, setIsRoleDisabled] = useState(false);

  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    officeAddress: '',
    organization_role: UserOrganisationRole.lite,
    expiry: new Date(
      new Date().setFullYear(new Date().getFullYear() + 1)
    ).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }),
    license: UserLicense.Go,
  };

  const validationSchema = yup.object().shape({
    firstName: yup
      .string()
      .required('First name is required')
      .max(245, 'Must be no longer than 245 characters'),
    lastName: yup
      .string()
      .required('Last name is required')
      .max(245, 'Must be no longer than 245 characters'),
    email: yup
      .string()
      .email('Email must be a valid email address')
      .required('Email is required'),
    company: yup
      .string()
      .required('Company name is required')
      .max(245, 'Must be no longer than 245 characters'),
    officeAddress: yup
      .string()
      .required('Office address is required')
      .max(245, 'Must be no longer than 245 characters'),
    organization_role: yup
      .string()
      .oneOf(['admin', 'standard', 'lite'])
      .required('Organisation Role is required'),
    expiry: yup.date().required('Date is required'),
    license: yup
      .string()
      .oneOf(['pro', 'go', 'all'])
      .required('License is required'),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm<UsersFormFields>({
    defaultValues: defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const selectedLicense = watch('license');

  React.useEffect(() => {
    if (selectedLicense === UserLicense.Go) {
      setRoleOptions([{ name: 'Go', value: UserOrganisationRole.lite }]); // Only one option for 'Go' license
      setValue('organization_role', UserOrganisationRole.lite); // Set default value
      setIsRoleDisabled(true);
    } else if (selectedLicense === UserLicense.Pro) {
      setRoleOptions([
        { name: 'Admin', value: UserOrganisationRole.admin },
        { name: 'Pro', value: UserOrganisationRole.standard },
      ]); // Only two options for 'Pro' license
      setValue('organization_role', UserOrganisationRole.standard); // Set default value
      setIsRoleDisabled(false);
    } else {
      setRoleOptions(ORGANISATION_ROLE_OPTIONS); // Default options
      setIsRoleDisabled(false);
    }
  }, [selectedLicense, setValue]);

  const handleCreate = async (data: UsersFormFields) => {
    const result = await addUserInvite(data, Number(orgId));
    if (result !== undefined) {
      setUpdateStarted(true);
    }
  };

  const onDialogClose = useCallback(() => {
    setUpdateStarted(false);
    history.push(`${ORGANISATIONS_VIEW_ROUTE.replace(':orgId', orgId)}`);
  }, [history, orgId]);

  return (
    <Box className={classes.content}>
      {matchesMediaQuery ? (
        <TopPannel>
          <Title
            onRedirectLinkClick={handleRedirectLinkClick}
            pageName={'Back to all users'}
          />
        </TopPannel>
      ) : (
        <Box textAlign="left" mr={4} ml={4}>
          <Title
            onRedirectLinkClick={handleRedirectLinkClick}
            pageName={'Back to all users'}
          />
        </Box>
      )}

      <Box
        ml={{ xs: 4, sm: 4, md: 4, lg: 10 }}
        mr={{ xs: 4, sm: 4, md: 4, lg: 20 }}
      >
        <Typography
          variant="h4"
          color="textPrimary"
          align="left"
          classes={{ root: classes.title }}
        >
          Add user
        </Typography>
        <Divider />
        <Grid container direction="row">
          <Grid item xs={12} sm={12} md={7}>
            {errorInv && (
              <Box mt={8} textAlign={'left'}>
                <Typography variant={'subtitle2'} color={'error'}>
                  {errorInv}
                </Typography>
              </Box>
            )}
            <Box mt={8}>
              <Box>
                <StyledInput
                  name="firstName"
                  label="First name"
                  control={control}
                  error={!!errors.firstName}
                  classnames={{ formControl: classes.input }}
                />
                {errors.firstName && (
                  <Typography
                    variant="body1"
                    color="error"
                    className={classes.errorMessage}
                    align="left"
                  >
                    {errors.firstName.message}
                  </Typography>
                )}
              </Box>
              <Box mt={7}>
                <StyledInput
                  name="lastName"
                  label="Last name"
                  control={control}
                  error={!!errors.lastName}
                  classnames={{ formControl: classes.input }}
                />
                {errors.lastName && (
                  <Typography
                    variant="body1"
                    color="error"
                    className={classes.errorMessage}
                    align="left"
                  >
                    {errors.lastName.message}
                  </Typography>
                )}
              </Box>
              <Box mt={7}>
                <StyledInput
                  name="email"
                  label="Email"
                  control={control}
                  error={!!errors.email}
                  classnames={{ formControl: classes.input }}
                />
                {errors.email && (
                  <Typography
                    variant="body1"
                    color="error"
                    className={classes.errorMessage}
                    align="left"
                  >
                    {errors.email.message}
                  </Typography>
                )}
              </Box>
              <Box mt={7}>
                <Typography variant="subtitle1" align="left">
                  License
                </Typography>
                <Controller
                  name={'license'}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      value={value}
                      onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                        onChange(e.target.value);
                      }}
                      className={classes.input}
                      classes={{ select: classes.select }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {USER_LICENSE_OPTIONS.map((option, i) => (
                        <MenuItem key={i} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </Box>
            </Box>
            <Box mt={7}>
              <StyledInput
                name="company"
                label="Company name"
                control={control}
                error={!!errors.company}
                classnames={{ formControl: classes.input }}
              />
              {errors.company && (
                <Typography
                  variant="body1"
                  color="error"
                  className={classes.errorMessage}
                  align="left"
                >
                  {errors.company.message}
                </Typography>
              )}
            </Box>
            <Box mt={7}>
              <StyledInput
                name="officeAddress"
                label="Office address"
                control={control}
                error={!!errors.officeAddress}
                classnames={{ formControl: classes.input }}
              />
              {errors.officeAddress && (
                <Typography
                  variant="body1"
                  color="error"
                  className={classes.errorMessage}
                  align="left"
                >
                  {errors.officeAddress.message}
                </Typography>
              )}
            </Box>
            <Box mt={7}>
              <Typography variant="subtitle1" align="left">
                Organisation Role
              </Typography>
              <Controller
                name={'organization_role'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    value={value}
                    onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                      onChange(e.target.value);
                    }}
                    className={classes.input}
                    classes={{ select: classes.select }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                    disabled={isRoleDisabled}
                  >
                    {roleOptions.map((option, i) => (
                      <MenuItem key={i} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Box>
            <Box mt={7} display={'flex'} flexDirection={'column'} mb={7}>
              <Typography variant="subtitle1" align="left">
                Subscription expires
              </Typography>
              <Controller
                name="expiry"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    value={value}
                    onChange={onChange}
                    InputProps={{ className: classes.input }}
                    minDate={new Date()}
                    renderInput={(props) => <TextField {...props} />}
                  />
                )}
              />
              {errors.expiry && (
                <Typography
                  variant="body1"
                  color="error"
                  className={classes.errorMessage}
                  align="left"
                >
                  {errors.expiry?.message}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <Box mt={8} ml={{ xs: 4, sm: 4, md: 4, lg: 15 }}>
              <Box display="flex" mt={5}>
                <Box mr={2}>
                  <Button
                    color="secondary"
                    disabled={updatingInv}
                    variant="contained"
                    disableElevation
                    onClick={handleRedirectLinkClick}
                    classes={{ root: classes.button }}
                  >
                    <SvgIcon
                      component={Cancel}
                      classes={{ root: classes.icon }}
                    ></SvgIcon>
                    Cancel
                  </Button>
                </Box>

                <Button
                  type="submit"
                  disabled={updatingInv}
                  color="secondary"
                  variant="contained"
                  disableElevation
                  onClick={handleSubmit(handleCreate)}
                  classes={{ root: classes.button }}
                >
                  <SvgIcon
                    component={SaveChanges}
                    classes={{ root: classes.icon }}
                  ></SvgIcon>
                  Save changes
                </Button>
              </Box>
              <Box mt={5}>{updatingInv && <CircularProgress />}</Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <WrappedDialog
        open={updateStarted && !updatingInv}
        handleDialogClose={onDialogClose}
        dialogTitle={'Thank you'}
      >
        <DialogContent>
          <Box display="flex">
            <Typography>Your changes have been saved.</Typography>
            <Typography>An email is sent to the user.</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box mt={3} mb={3} mr={8}>
            <Button
              onClick={onDialogClose}
              color="secondary"
              variant="contained"
              disableElevation
              classes={{ root: classes.button }}
            >
              Close
            </Button>
          </Box>
        </DialogActions>
      </WrappedDialog>
    </Box>
  );
};

export default UsersAddContent;
