import { Box, Typography } from '@material-ui/core';
import React, { useCallback, useContext, useRef } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { InvitesStore } from '../../providers/invites';
import { UsersStore } from '../../providers/users';
import { UsersListItem } from '../../services/users';
import { LAYOUT } from '../../utils/constants/layout.constants';
import { UserListType } from '../../utils/types/user';
import VirtualizedListItem from '../common/VirtualizedListItem';
import UsersListItemContent from './UsersListItemContent';

interface UsersListProps {
  data: UsersListItem[];
  handleOnScroll?: () => void;
  listType: UserListType;
}

interface ListRowProps {
  style: any;
  index: number;
  data: UsersListItem[];
  listType: UserListType;
}

const Row = ({ style, index, data, listType }: ListRowProps) => {
  const { resetDevice } = useContext(UsersStore);
  const { remove, chaseInvitedUser } = useContext(InvitesStore);

  const onRemove = useCallback(
    (user: UsersListItem) => {
      remove(user.id);
    },
    [remove]
  );

  const onResetDevice = useCallback(
    (user: UsersListItem) => {
      resetDevice(user);
    },
    [resetDevice]
  );

  const onChaseInvitedUser = useCallback(
    (user: UsersListItem) => {
      chaseInvitedUser(user);
    },
    [chaseInvitedUser]
  );

  return (
    <VirtualizedListItem
      style={{
        ...style,
        width: 'calc(100% - 20px)',
        marginLeft: 10,
        marginRight: 10,
        marginTop: 10,
      }}
    >
      <UsersListItemContent
        id={data[index]?.id}
        jobTitle={data[index]?.jobTitle}
        firstName={data[index]?.firstName}
        lastName={data[index]?.lastName}
        company={data[index]?.company}
        expiry={data[index]?.expiry}
        type={data[index]?.type}
        archived={data[index]?.archived}
        permission={data[index]?.permission}
        organization_role={data[index]?.organization_role}
        email={data[index]?.email}
        onRemove={() => onRemove(data[index])}
        onResetDevice={() => onResetDevice(data[index])}
        listType={listType}
        onChaseInvitedUser={() => onChaseInvitedUser(data[index])}
        accessLevel={data[index]?.access_level}
        license={data[index]?.license}
        disabled={data[index]?.disabled}
      ></UsersListItemContent>
    </VirtualizedListItem>
  );
};

const UsersList: React.FC<UsersListProps> = ({
  data,
  handleOnScroll,
  listType,
}): JSX.Element => {
  const listRef = useRef(null);

  const {
    state: { loading },
  } = useContext(UsersStore);

  const itemsCount = data.length;

  const loadMoreItems = useCallback(
    (startIndex: number, stopIndex: number) => {
      const shouldReload = itemsCount - stopIndex <= 2;

      if (shouldReload && !loading) {
        handleOnScroll && handleOnScroll();
      }
    },
    [loading, itemsCount, handleOnScroll]
  );

  const isItemLoaded = useCallback((_index: number) => {
    //TODO: dynamic loading is not available yet
    return false;
  }, []);

  return (
    <>
      {data.length > 0 ? (
        <>
          {listType === UserListType.Users ? (
            <AutoSizer>
              {({ height, width }: any) => (
                <FixedSizeList
                  height={height}
                  itemCount={itemsCount}
                  itemSize={LAYOUT.VirtuilizedUserListItemHeight}
                  width={width}
                  itemData={data}
                  ref={listRef}
                >
                  {(props) => Row({ ...props, listType })}
                </FixedSizeList>
              )}
            </AutoSizer>
          ) : (
            <AutoSizer>
              {({ height, width }: any) => (
                <InfiniteLoader
                  isItemLoaded={isItemLoaded}
                  itemCount={itemsCount}
                  loadMoreItems={loadMoreItems}
                >
                  {({ onItemsRendered, ref }) => (
                    <FixedSizeList
                      onItemsRendered={onItemsRendered}
                      height={height}
                      itemCount={itemsCount}
                      itemSize={LAYOUT.VirtuilizedUserListItemHeight}
                      width={width}
                      itemData={data}
                      ref={ref}
                    >
                      {(props) => Row({ ...props, listType })}
                    </FixedSizeList>
                  )}
                </InfiniteLoader>
              )}
            </AutoSizer>
          )}
        </>
      ) : (
        <Box ml={5} mt={2} textAlign={'left'}>
          <Typography>No users in this section</Typography>
        </Box>
      )}
    </>
  );
};

export default UsersList;
