import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  updateDoc,
} from '@firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { Article } from '../utils/types/article';
import { db } from './firebase';

export const createFirebase = async (article: Article): Promise<Article> => {
  const articlesCollection = collection(db, 'articles');

  try {
    const createdDocRef = await addDoc(articlesCollection, article);
    return { id: createdDocRef.id, ...article };
  } catch (error) {
    console.error('Error creating article:', error);
    throw error;
  }
};

export const updateFirebase = async (article: Article): Promise<Article> => {
  if (!article.id) {
    throw new Error('Article ID is undefined');
  }

  const articleRef = doc(db, 'articles', article.id);

  try {
    await updateDoc(articleRef, { ...article, user: '' });
    return article;
  } catch (error) {
    console.error('Error updating article:', error);
    throw error;
  }
};

export const deleteFirebase = async (article: Article): Promise<Article> => {
  if (!article.id) {
    throw new Error('Article ID is undefined');
  }

  const articleRef = doc(db, 'articles', article.id);

  try {
    await deleteDoc(articleRef);
    return article;
  } catch (error) {
    console.error('Error deleting article:', error);
    throw error;
  }
};

export const getBatchFirebase = async (
  lastDoc: Article | null
): Promise<Array<Article>> => {
  let articles;

  const articlesCollection = collection(db, 'articles');
  const queryOptions = orderBy('published', 'desc');

  if (lastDoc && lastDoc.id) {
    const lastDocRef = doc(db, 'articles', lastDoc.id);
    const lastDocSnapshot = await getDoc(lastDocRef);
    articles = await getDocs(
      query(
        articlesCollection,
        queryOptions,
        startAfter(lastDocSnapshot),
        limit(20)
      )
    );
  } else {
    articles = await getDocs(
      query(articlesCollection, queryOptions, limit(20))
    );
  }

  return articles.docs.map((doc) => ({
    id: doc.id,
    title: doc.data().title,
    image: doc.data().image,
    extract: doc.data().extract,
    rawText: doc.data().rawText,
    text: doc.data().text,
    user: doc.data().user,
    brightness: doc.data().brightness,
    ratio: doc.data().ratio,
    published: new Date(doc.data().published.seconds * 1000),
    tileSize: doc.data().tileSize,
    target: doc.data().target,
  }));
};

export const uploadImageToFirebase = async (file: File): Promise<string> => {
  const storage = getStorage();
  const storageRef = ref(
    storage,
    `articles/${Date.now()}_${file.name.replace(/\s+/g, '_')}`
  );

  try {
    await uploadBytes(storageRef, file);

    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  } catch (error) {
    console.error('Error uploading image to Firebase:', error);
    throw error;
  }
};
